import { FC, Fragment, ReactNode } from 'react'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'
import { Divider } from '@lifedot/atoms/Divider'
import { ServiceInfos, serviceInfos, Sitemaps, sitemaps } from './links'
import dayjs from 'dayjs'
import Image from 'next/image'
import { CampaignNote } from '../CampaignNote'

const styles = {
  root: css({
    backgroundColor: palette.gray2
  }),
  layout: css({
    maxWidth: 1080,
    margin: '0 auto',
    padding: 20,
    [mq('sp')]: {
      padding: '16px 12px'
    }
  }),
  topMargin: css({
    marginTop: 20
  }),
  flexSwitch: css({
    display: 'flex',
    [mq('sp')]: {
      flexWrap: 'wrap'
    }
  })
}

type FooterProps = {
  children?: ReactNode
}

export const Footer: FC<FooterProps> = (props) => {
  return (
    <footer css={styles.root}>
      <div css={styles.layout}>
        <Sitemap items={sitemaps} />
        <Sns />
        <Divider dark />
        <ServiceInfo items={serviceInfos} />
        <Notes />
        {props.children}
      </div>
      <CopyRight />
    </footer>
  )
}

const copyStyles = {
  root: css({
    padding: '12px 0',
    textAlign: 'center',
    backgroundColor: palette.black,
    color: palette.white
  })
}

const year = dayjs().year()

export const CopyRight: FC = () => {
  return (
    <p css={[typography.textXS, copyStyles.root]}>
      &copy; 2017-{year} お墓・霊園探しならライフドット
    </p>
  )
}

const sitemapStyles = {
  root: css({
    hr: {
      width: 1,
      backgroundColor: palette.gray3,
      margin: 0
    },
    ul: {
      padding: '4px 16px',
      width: '100%',
      li: {
        ...typography.textS
      },
      [mq('sp')]: {
        padding: '0',
        ':not(:first-of-type)': {
          paddingTop: 8
        },
        'li:not(:first-of-type)': {
          display: 'inline-block',
          marginRight: 8
        }
      }
    }
  })
}

interface SitemapProps {
  items: Sitemaps
}

const Sitemap: FC<SitemapProps> = (props) => (
  <nav css={[sitemapStyles.root, styles.flexSwitch]}>
    {props.items.map((block, index) => (
      <Fragment key={index}>
        <hr />
        <ul>
          {block.map((link) => (
            <li key={link.href}>
              {link.bold ? (
                <a href={link.href}>
                  <b>{link.title}</b>
                </a>
              ) : (
                <a href={link.href}>{link.title}</a>
              )}
            </li>
          ))}
        </ul>
      </Fragment>
    ))}
    <hr />
  </nav>
)

const snsStyles = {
  box: css({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingBottom: 16,
    gap: 20
  }),
  wrap: css({
    display: 'flex',
    alignItems: 'center',
    gap: 16
  }),
  icon: css({
    fontSize: 0
  }),
  title: css({
    fontWeight: 'bold'
  })
}

const Sns: FC = () => (
  <div css={[styles.topMargin, snsStyles.box]}>
    <p css={[snsStyles.title, typography.textM]}>ライフドット公式SNS</p>
    <div css={snsStyles.wrap}>
      <a
        href="https://www.facebook.com/ateam.lifedot/"
        target="_blank"
        rel="noopener noreferrer"
        css={snsStyles.icon}
      >
        <Image
          src="https://s3.lifedot.jp/uploads/ending/path/86605/Facebook_Logo_Primary.png"
          alt="ライフドットFacebook公式アカウント"
          width={32}
          height={32}
        />
      </a>
      <a
        href="https://twitter.com/lifedot_ending"
        target="_blank"
        rel="noopener noreferrer"
        css={snsStyles.icon}
      >
        <Image
          src="https://s3.lifedot.jp/uploads/ending/path/86604/logo-black.png"
          alt="ライフドットTwitter公式アカウント"
          width={28}
          height={28}
        />
      </a>
    </div>
  </div>
)

const serviceStyles = {
  list: css({
    li: {
      display: 'inline-block',
      marginRight: 8,
      a: typography.textXS
    }
  })
}

interface ServiceInfoProps {
  items: ServiceInfos
}

const ServiceInfo: FC<ServiceInfoProps> = (props) => (
  <nav css={styles.topMargin}>
    <ul css={serviceStyles.list}>
      {props.items.map((link) => (
        <li key={link.href}>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href={link.href}
            target={link.target ? '_blank' : undefined}
            rel={link.target ? 'noopener noreferrer' : undefined}
          >
            {link.title}
          </a>
        </li>
      ))}
    </ul>
  </nav>
)

const notesStyles = {
  caution: css({
    display: 'grid',
    gap: 12,
    padding: 16,
    backgroundColor: palette.white,
    dd: {
      display: 'list-item',
      marginLeft: '1rem'
    }
  }),
  jpx: css({
    display: 'flex',
    marginLeft: 12,
    [mq('sp')]: {
      margin: '5mm calc(5mm - 12px) 0' //東証ロゴ利用規定にて、ロゴとの間に5mm以上の余白が必要
    }
  }),
  jpxLogo: css({
    marginLeft: '5mm', //東証ロゴ利用規定にて、文字との間に5mm以上の余白が必要
    flexShrink: 0
  })
}

const Notes: FC = () => (
  <div css={[styles.topMargin, styles.flexSwitch]}>
    <div css={[typography.textXS, notesStyles.caution]}>
      <dl>
        <dt>■当サイトに関する注意事項</dt>
        <dd>
          当サイトで提供する商品の情報にあたっては、十分な注意を払って提供しておりますが、情報の正確性その他一切の事項についてを保証をするものではありません。
        </dd>
        <dd>
          お申込みにあたっては、提携事業者のサイトや、利用規約をご確認の上、ご自身でご判断ください。
        </dd>
        <dd>
          当社では各商品のサービス内容及びキャンペーン等に関するご質問にはお答えできかねます。提携事業者に直接お問い合わせください。
        </dd>
        <dd>
          本ページのいかなる情報により生じた損失に対しても当社は責任を負いません。
        </dd>
        <dd>
          なお、本注意事項に定めがない事項は当社が定める「利用規約」
          が適用されるものとします。
        </dd>
      </dl>
      <CampaignNote />
    </div>
    <div css={notesStyles.jpx}>
      <p css={typography.textXS}>
        「ライフドット」は株式会社エイチームライフデザインが運営するサービスです。
        <br />
        株式会社エイチームライフデザインは株式会社エイチームホールディングス（東証プライム市場上場）のグループ企業です。
        <br />
        証券コード：3662
      </p>
      <figure css={notesStyles.jpxLogo}>
        <Image
          src="https://s3.lifedot.jp/uploads/ending/path/72527/logo_jpx.png"
          alt="東証プライム市場上場"
          width={64}
          height={71}
        />
      </figure>
    </div>
  </div>
)
